@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:opsz,wght@6..96,400;6..96,500;6..96,600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', 'Roboto', sans-serif;
}

:root {
  font-size: 16px;
  scroll-behavior: smooth;
}
#root {
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  :root {
    font-size: 15px;
  }
}
@media screen and (max-width: 1000px) {
  :root {
    font-size: 14px;
  }
}

@media screen and (max-width: 750px) {
  :root {
    font-size: 12px;
  }
}
